import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import NewsPage from './pages/NewsPage';
import CertificatePage from './pages/CertificatePage';
import Category1 from './pages/Category1';
import NotFoundPage from './pages/NotFoundPage';
import CssBaseline from '@mui/material/CssBaseline';
import AdminPage from './pages/AdminPage';
import LoginPage from './pages/LoginPage';
import PrivateRoute from './PrivateRoute';
import NewsAdminPage from './pages/NewsAdminPage'
import Layout from './Layout'; 
import PhraSomdejPage from './pages/PhraSomdejPage'
import LuangPuThuatPage from './pages/LuangPuThuatPage'
import LuangPhoNgoenPage from './pages/LuangPhoNgoenPage'
import ETCPage from './pages/ETCPage'
import './App.css';


function App() {
  return (
    <Router>
      <CssBaseline />
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="/phrasomdej" element={<PhraSomdejPage />} />
          <Route path="/luangputhuat" element={<LuangPuThuatPage />} />
          <Route path="/luangphongoen" element={<LuangPhoNgoenPage />} />
          <Route path="/etc" element={<ETCPage />} />
          <Route path="/login" element={<LoginPage />} />

          <Route
            path="/admin"
            element={
              <PrivateRoute>
                <AdminPage />
              </PrivateRoute>
            }
          />
            <Route
            path="/news-admin"
            element={
              <PrivateRoute>
                <NewsAdminPage />
              </PrivateRoute>
            }
          />

          <Route path="/certificate" element={<CertificatePage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
        
      </Routes>
    </Router>
  );
}

export default App;
