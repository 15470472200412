import React from 'react';
import { Card, Link, Typography, Avatar, Box} from '@mui/material';

const BusinessCard = () => {
  return (
    <Card 
      sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        backgroundColor: '#930402', // สีพื้นหลังแดงเข้ม
        color: '#fff', // สีตัวหนังสือสีขาว
        padding: '6px',
        // border: '4px solid #FFD700', // กรอบสีทอง
     
        maxWidth: 600,
        margin: 'auto',
        marginBottom: 6
      }}
    >
    <Box sx={{padding: 2, border: '2px solid  #fcae24',alignItems: 'center',  display: 'flex',width: '100%'}}>
      <Avatar
        src="images/Yotin.png"
        alt="User Image"
        sx={{
          width: 150,
          height: 150,
          marginRight: {xs: '20px',md:'40px'},
          marginLeft: {xs: '10px',md:'20px'}
        }}
      />
      <Box>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          โยธิน จัดให้
        </Typography>
        <Typography variant="subtitle1">
          นายดำรงค์ศักดิ์ คล้ายศรี
        </Typography>
        <Typography variant="body1">
          ที่อยู่: กระบี่
        </Typography>
        <Typography variant="body1">
            โทร:   {' '}
            <Link href="tel:0654642859" color="inherit" underline="none" sx={{ display: 'inline' }}>
                0654642859
            </Link>
            {' '}
            <Link href="tel:0989098524" color="inherit" underline="none" sx={{ display: 'inline' }}>
                0989098524
            </Link>
            </Typography>

        <Link href="https://page.line.me/134shmyw" target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none', color: 'inherit' }}>
                        <Typography variant="body1">Line: โยธินจัดให้</Typography>
                      </Link>
      </Box>
      </Box>
    </Card>
  );
};

export default BusinessCard;