import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ProductDialog from './ProductDialog'
import { useTheme } from '@mui/material/styles';

const getStateLabel = (stateType) => {
    switch (stateType) {
      case 'new':
        return 'มาใหม่';
      case 'sale':
        return 'เปิดให้บูชา';
      case 'sold':
        return 'ให้บูชาแล้ว';
      case 'show':
        return 'โชว์พระ';
      default:
        return 'ไม่ทราบสถานะ';
    }
  };
  
  // ฟังก์ชันกำหนดสีของ Chip ตาม state_type
  const getChipColor = (stateType, theme) => {
    switch (stateType) {
      case 'new':
        return theme.palette.newitem.main; // Use theme color
      case 'sale':
        return theme.palette.saleitem.main; // Use theme color
      case 'sold':
        return theme.palette.solditem.main; // Use theme color or customize
      case 'show':
        return theme.palette.showitem.main; // Use theme color
      default:
        return theme.palette.primary.main; // Fallback to primary color
    }
};

export default function ProductCard({ product,swiper }) {
    const theme = useTheme();
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
    }
    

    return (
        <Card
        sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%', // Ensure the card expands to fill its container
            backgroundColor: theme.palette.background.default,
        }}
   
        >

            <Box
                sx={{
                    height: 200,
                    overflow: 'hidden',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <img
                   src={product?.images[0]?.image_path || 'uploads/amulet_default.png'} // ดึงภาพจาก API
                   alt={product?.item_name}
                    style={{
                        height: '100%',
                        width: 'auto',
                        objectFit: 'cover',
                    }}
                />
            </Box>
            <CardContent
                sx={{
                flexGrow: 1, // ทำให้ CardContent ขยายตัวเพื่อเติมพื้นที่ที่เหลือ
                }}
            >
                <Typography gutterBottom variant="h5" component="div">
                    {product.item_name} {/* ชื่อสินค้า */}
                </Typography>
                {!swiper && (
                  <Typography variant="body2" >
                    {product.description.length > 150 ? `${product.description.slice(0, 150)}...` : product.description} {/* คำอธิบายสินค้า */}
                  </Typography>
                )}
            </CardContent>
            <CardActions
                sx={{
                display: 'flex',
                justifyContent: 'space-between', // จัดให้ Chip อยู่ซ้าย และ Button อยู่ขวา
                alignItems: 'center', // จัดเรียงในแนวแกนกลาง
                padding: '8px', // กำหนด padding สำหรับความสวยงาม
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1, // กำหนดระยะห่างระหว่าง Chip
                    }}
                    >
                <Chip
                label={getStateLabel(product.state_type)} // แสดงสถานะสินค้า
                 variant="outlined"
                sx={{
                    borderColor: getChipColor(product.state_type,theme), // ใช้สีของขอบจากธีม
                    color: getChipColor(product.state_type,theme), // ให้สีข้อความเข้ากับ background
                }}
                />

                {product.super_hit && (
                            <Chip
                            label="Hit!"  
                            variant="outlined"    
                            sx={{
                                borderColor: '#ffc107', // หรือสีที่คุณต้องการ
                                color:'#ffc107',
                            }}
                            />
                        )}
                </Box>

                <Button size="small" onClick={handleOpenDialog}  
                sx={{
                  color: theme.palette.customButton.main,
                  backgroundColor: theme.palette.customButton.background,
                  '&:hover': {
                    backgroundColor: theme.palette.customButton.hover,
                  },
                  }}>
                    รายละเอียด
                  </Button>
                <ProductDialog openDialog={openDialog} onClose={handleCloseDialog} product={product} />
            </CardActions>
        </Card>
    );
}
