// Poster.tsx
import * as React from 'react';
import Box from '@mui/material/Box';

const Poster = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: {
          // xs: '15em', // ความสูงสำหรับหน้าจอขนาดเล็ก (mobile)
          // sm: '20em', // ความสูงสำหรับหน้าจอขนาดกลาง (tablet)
          // md: 200, // ความสูงสำหรับหน้าจอขนาดใหญ่ขึ้น (desktop)
          lg: 300, // ความสูงสำหรับหน้าจอขนาดใหญ่กว่า (large desktop)
          xl: 400, // ความสูงสำหรับหน้าจอขนาดใหญ่มาก (extra large desktop)
        },
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f0f0f0', // สีพื้นหลังเมื่อภาพโหลดช้า
      }}
    >
      <img
        src="/images/background.jpg" // เปลี่ยน path ให้ตรงกับที่เก็บไฟล์
        alt="Poster"
        style={{
          width: '100%',
          height: '100%', // ให้ความสูงของภาพคงที่ และให้ความกว้างเต็มที่
          objectFit: 'cover',
          objectPosition: 'left' // ทำให้ภาพครอบคลุมพื้นที่ที่กำหนด
        }}
      />
    </Box>
  );
};

export default Poster;
