import React, { useEffect, useState } from 'react';
import {  Typography, Paper, Box } from '@mui/material';
import PCardGrid from '../components/PCardGrid';
import Poster from '../components/Poster';
import BusinessCard from '../components/BusinessCard'

const HomePage = () => {
  const [products, setProducts] = useState([]); // ใช้สถานะสำหรับผลิตภัณฑ์
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/api/inventory/filter?catalog_type=พระเครื่องอื่นๆ');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <Poster />
      <Paper style={{ padding: 40 }}>
        <Typography variant="h1" gutterBottom>
          พระเครื่องอื่นๆ
        </Typography>
        <BusinessCard/>
        <Typography variant="subtitle1" sx={{ marginLeft: '1em', lineHeight: 1 }} gutterBottom>
        <span style={{ color: 'yellow', display: 'block', marginBottom: '8px',fontSize:'24px' }}>
          หลักการรับประกัน
        </span>
        <ul style={{ paddingLeft: '20px', margin: 0, listStyleType: 'none' }}>
          <li style={{ marginBottom: '8px',  position: 'relative', lineHeight: '1.4'  }}>
            <span style={{ position: 'absolute', left: '-20px' }}>-</span>
            ซื่อสัตย์ ซื่อตรง คุยง่าย พระสวย รับประกันพระแท้ทุกองค์ตามสากลนิยม
          </li>
          <li style={{ marginBottom: '8px',  position: 'relative', lineHeight: '1.4'  }}>
            <span style={{ position: 'absolute', left: '-20px' }}>-</span>
            ได้รับพระแล้วหากเป็นพระเก๊ที่ตรวจสอบถูกต้อง คืนเงินให้เต็ม 100% ภายในสามวัน (พระต้องอยู่ในสภาพสมบูรณ์เท่านั้น)
          </li>
          <li style={{ marginBottom: '8px',  position: 'relative', lineHeight: '1.4'  }}>
            <span style={{ position: 'absolute', left: '-20px' }}>-</span>
            ภายใน 5 วันตรวจเช็คสมบูรณ์แล้ว ถือว่าการขายสมบูรณ์ลูกค้าพึงพอใจ
          </li>
          <li style={{ marginBottom: '8px',  position: 'relative', lineHeight: '1.4'  }}>
            <span style={{ position: 'absolute', left: '-20px' }}>-</span>
            ลูกค้าต้องการเปลี่ยนหรือเทิร์นพระองค์ใหม่ที่มีราคาสูงกว่าไม่หักเปอร์เซ็นต์ แต่พระต้องอยู่ในสภาพเดิมและสมบูรณ์เท่านั้น
          </li>
          <li style={{ marginBottom: '8px',  position: 'relative', lineHeight: '1.4'  }}>
            <span style={{ position: 'absolute', left: '-20px' }}>-</span>
            พระทุกองค์จากทางร้าน รับประกันพระแท้ตามมาตรฐานสมาคมผู้นิยมพระเครื่องพระบูชาไทยตลอดชีพ รับซื้อคืนหัก 30% ในระยะเวลา 30 วัน พระต้องอยู่ในสภาพเดิม ไม่ล้างเช็ดผิว หรือชำรุดเสียหาย
          </li>
        </ul>
          </Typography>
        <Box sx={{ padding: 2, marginBottom: 4 }}>
          <PCardGrid role='user' products={products} /> 
        </Box>
      </Paper>
    </>
  );
};

export default HomePage;
