import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import DialogContent from '@mui/material/DialogContent'
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  // '& .MuiDialogContent-root': {
  //   padding: theme.spacing(2),
  // },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  [theme.breakpoints.up('sm')]: {
    '& .MuiDialog-paper': {
      width: '80%', // ขนาดกว้างตามที่คุณต้องการ
      maxWidth: '1200px', // ขนาดสูงสุด
    },
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiDialog-paper': {
      width: '90%', // ขนาดกว้างสำหรับหน้าจอเล็ก
      maxWidth: '800px', // ขนาดสูงสุดสำหรับหน้าจอเล็ก
    },
  },
}));

const CarouselContainer = styled('div')(({ theme }) => ({
  '& .carousel .thumbs-wrapper': {
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
 
  },
  '& .carousel .thumb': {
    margin: '4px', // ระยะห่างระหว่าง thumbnail

  },

  // '& .carousel .thumbs': {

  //   whiteSpace: 'wrap',
  // },


}));

const getStateLabel = (stateType) => {
  switch (stateType) {
    case 'new':
      return 'มาใหม่';
    case 'sale':
      return 'เปิดให้บูชา';
    case 'sold':
      return 'ให้บูชาแล้ว';
    case 'show':
      return 'โชว์พระ';
    default:
      return 'ไม่ทราบสถานะ';
  }
};

// ฟังก์ชันกำหนดสีของ Chip ตาม state_type
const getChipColor = (stateType) => {
  switch (stateType) {
    case 'new':
      return '#2c387e'; 
    case 'sale':
      return '#008394'; 
    case 'sold':
      return '#B22222';
    case 'show':
      return '#1769aa'; 
    default:
      return 'black'; 
  }
};


export default function ProductDialog({ openDialog, onClose, product }) {

  const formattedPrice = new Intl.NumberFormat('th-TH').format(product.price);
  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={openDialog}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ padding: '16px 0' }} dividers>
      <Stack
          direction={{ sm: 'column', md: 'row' }}
          spacing={2}
          sx={{
              padding: '6px',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%', // Ensure Stack takes up full height if needed
   
          }}
      >
          <Box sx={{ flex: '60%',width:'100%',marginTop:'20px' }}>
          <CarouselContainer>
            <Carousel showThumbs={true} showStatus={false} infiniteLoop={true}>
              {product.images && product.images.length > 0 ? (
                product.images.map((image, index) => (
                  <div key={index}>
                    <img
                      src={image?.image_path || 'uploads/amulet_default.png'}
                      alt={`Product ${index}`}
                      style={{
                        maxWidth: '500px',
                        height: 'auto',
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                ))
              ) : (
                <div>
                  <img
                    src="uploads/amulet_default.png"
                    alt="Default Product"
                    style={{
                      maxWidth: '500px',
                      height: 'auto',
                      objectFit: 'cover',
                    }}
                  />
                </div>
              )}
            </Carousel>
          </CarouselContainer>
          </Box>
          <Box sx={{ flex: '40%' }}>
              <Typography variant='h4' gutterBottom>
                  {product.item_name}
              </Typography>
              <Chip
                label={getStateLabel(product.state_type)} // แสดงสถานะสินค้า
                 variant="outlined"
                sx={{
                    borderColor: getChipColor(product.state_type), // ใช้สีของขอบจากธีม
                    color: getChipColor(product.state_type), // ให้สีข้อความเข้ากับ background
                }}
                />
              <Box sx={{ borderBottom: '2px solid #ccc', my: 2 }} />
              <Typography gutterBottom>
                  {product.description}
              </Typography>
              <Typography variant='h5' gutterBottom color='orange'>
                  {formattedPrice} บาท
              </Typography>
              <Typography variant='h5' gutterBottom>
                  Contact
              </Typography>
              <Box sx={{ borderBottom: '2px solid #ccc', my: 2 }} />
              <Stack spacing={1} sx={{ alignItems: 'flex-start' }}>
                  <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                      <Chip label="Line" color="primary" />
                      <Link href="https://page.line.me/134shmyw" target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none', color: 'inherit' }}>
                        <Typography>โยธินจัดให้</Typography>
                      </Link>
                                          {/* <Typography>{product.contact_line}</Typography> */}
                  </Stack>
                  <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                      <Chip label="phone" color="primary" />
                      {/* <Typography>{product.contact_phone}</Typography> */}
                      <Link href={`tel:0654642859`} color="inherit" underline="none">
                      <Typography> 0654642859</Typography>
                      </Link>
                     <Link href={`tel:0989098524`} color="inherit" underline="none">
                      <Typography> 0989098524</Typography>
                      </Link>
                  </Stack>
                  <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                      <Chip label="type" color="primary" />
                      <Typography>{product.catalog_type}</Typography>
                  </Stack>
              </Stack>
          </Box>
      </Stack>
      </DialogContent>
    </BootstrapDialog>
  );
}
